import { Link } from 'react-router-dom'
import parse from 'html-react-parser'
import Header from 'components/header'
import useBlogsScreen from './useBlogsScreen'
import './BlogsScreen.css'

const BlogsScreen = () => {
    const baseUrl = window.location.protocol + "//" + window.location.host
    const [isListing, details, contents, liste] = useBlogsScreen()

    return (
        <div>
            <Header title="blog" />
            <div>
                <div className="d-flex flex-row flex-wrap justify-content-center">
                    <div className="blog-page-head"><div className="fka-title"><span>Mes actualit&eacute;s</span></div></div>
                    <div className="blog-page-head"></div>
                </div>
                <div className="d-flex flex-row flex-wrap justify-content-center">
                    {isListing && 
                        <>
                            {liste.map(
                                item => (
                                <div className="fka-body" key={item.path}>
                                    <div className="fond-gris"><img src={baseUrl + item.img} className="item-img" alt="Blog illustration" />
                                    <div className="item-title"><span className="test"><Link to={item.path} className="item-link">{parse(item.title)}</Link><br/><span className="item-date">{item.date}</span></span></div></div>
                                    <div className="item-summary item-summary-font">{parse(item.summary)}</div>
                                </div>)
                            )}
                        </>
                    }
                    {!isListing && 
                        <>
                            <div className="item-details pt-4">
                                <span>publi&eacute; le {details.date}</span>
                                <h1>{parse(details.title)}</h1>
                                <p className="item-summary-font">{parse(details.summary)}</p>
                            </div>
                            <div className="item-details pb-4">
                                <img src={baseUrl + (details.imgDetails || details.img)} className="item-details-img" alt="blog details illustration" />
                                <span>{parse(contents)}</span>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default BlogsScreen