import TopBar from 'components/topbar'

const Header = ({title}) => {
    return (
        <div className="page-header">
            <TopBar />
            <div className="page-h text-center">
                <h1>{title}</h1>
            </div>
        </div>
    )
}

export default Header 