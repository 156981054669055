import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const useBlogsScreen = () => {
    const blogDir = '/blogs/'
    const { blogPath } = useParams()
    const [isListing, setIsListing] = useState(true)
    const [details, setDetails] = useState({title: '', summary: ''})
    const [contents, setContents] = useState('')
    const [liste, setListe] = useState([])
    useEffect (() => {
        fetch(blogDir + 'index.json')
            .then(r => r.json())
            .then(j => {
                setListe(j)
                if (blogPath) {
                    setIsListing(false)
                    setDetails(j.filter(item => item.path === blogPath)[0])
                    fetch(blogDir + blogPath + '.html')
                        .then(r => r.text())
                        .then(t => setContents(t))
                        .catch(_ => setIsListing(true))
                } else {
                    setIsListing(true)
                }
            })
    }, [blogPath])

    return [isListing, details, contents, liste]
}

export default useBlogsScreen