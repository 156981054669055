import Header from 'components/header'
import photo from 'resources/photo-fka.jpg'
import './AboutScreen.css'

const AboutScreen = () => {

    return (
        <div>
            <Header title="à propos" />
            <div className="page-content pt-4 pb-4">
                <div className="text-center">
                    <span className="text-a-propos">
                        ma&icirc;tre fran&ccedil;oise kouassi est une avocate exer&ccedil;ant<br/>
                        dans les domaines du droit priv&eacute; et du droit public.
                    </span>
                </div>
                <div className="a-propos-body">
                    <hr/>
                    <div className="a-propos-details ps-2">
                        <img src={photo} className="a-propos-details-img" alt="Françoise K." />
                        <span className="page-content-text">mettant l'humain au c<span className="fsoe">&oelig;</span>ur de ses actions, 
                            le cabinet fran&ccedil;oise kouassi avocat vous propose, avec professionnalisme,
                            tant en mati&egrave;re de conseil que dans le cadre de proc&eacute;dures contentieuses, 
                            un service personnalis&eacute; et adapt&eacute; &agrave; vos besoins.<br/><br/>
                            titulaire d'un doctorat en droit &agrave; l'universit&eacute; de poitiers 
                            et nantie de solides exp&eacute;riences dans l'enseignement sup&eacute;rieur et la recherche, 
                            ma&icirc;tre fran&ccedil;oise kouassi a su d&eacute;velopper de nombreuses comp&eacute;tences
                            qu'elle met en <span className="fsoe">&oelig;</span>uvre pour la satisfaction de sa client&egrave;le.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutScreen