import Header from 'components/header'
import useContactScreen from './useContactScreen'
import './ContactScreen.css'

const ContactScreen = () => {

    const [processing, success, error, sendMessage] = useContactScreen()

    return (
        <div>
            <Header title="contact" />
            <div className="d-flex flex-row flex-wrap justify-content-center pt-5 pb-5 ps-2 pe-2">
                <div className="contact-body-l">
                    <hr className="head-hr"/>
                    <span className="fka-text">Fran&ccedil;oise KOUASSI AVOCAT</span><br/><br/>
                    <span className="fka-address-text">26 Rue Pasteur, 17800 PONS</span><br/>
                    <span className="fka-address-text">05 46 92 16 58</span><br/>
                    <span className="fka-address-text">contact@francoise-kouassi-avocat.fr</span>
                </div>
                <div className="contact-body-r">
                    <form onSubmit={e => {e.preventDefault();sendMessage(e)}}>
                        <div className="form-content">
                            <input type="text" required className="form-control" id="name" name="name" placeholder="Votre nom"/><br/>
                            <input type="email" required className="form-control" id="email" name="email" placeholder="Votre email"/><br/>
                            <textarea required className="form-control" id="message" name="message" rows="5" placeholder="Votre message"></textarea>
                        </div>
                        {!processing && success && <div className="text-success pb-2 alert alert-success" role="alert">{success}</div>}
                        {!processing && error && <div className="text-danger pb-2 alert alert-danger" role="alert">{error}</div>}
                        {(processing && <div className="spinner-border text-dark" role="status"></div>) || <button type="submit" className="btn-fka-dark">envoyer</button>}
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ContactScreen