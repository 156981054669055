import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import HomeScreen from "./screens/home"
import AboutScreen from "./screens/about"
import ExpertiseScreen from "./screens/expertise"
import ContactScreen from "./screens/contact"
import BlogsScreen from "./screens/blog"
import Footer from "components/footer"
import 'resources/main.css'

function App() {
  return (
    <div className="App">
      <Router>
        <div>
          <div className="app-body">
            <Routes>
              <Route path="/" element={<HomeScreen />} />
              <Route path="/a-propos" element={<AboutScreen />} />
              <Route path="/expertise" element={<ExpertiseScreen />} />
              <Route path="/contact" element={<ContactScreen />} />
              <Route path="/blog" element={<BlogsScreen />} />
              <Route path="/blog/:blogPath" element={<BlogsScreen />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    </div>
  );
}

export default App;
