import logoDark from 'resources/logoDark.png'
import logoLight from 'resources/logoLight.png'
import linkedIn from 'resources/linkedInIcon.png'
import email from 'resources/emailIcon.png'
import { Link } from "react-router-dom"

const TopBar = ({fromHome}) => {
    const isHome = fromHome && fromHome === "true"

    return (
        <nav className="navbar navbar-light bg-none justify-content-between">
            <a className="navbar-brand ms-5" href="/"><img src={(isHome && logoDark) || (!isHome && logoLight)} height="50" className="d-inline-block align-top" alt="francoise-kouassi-avocat.fr" /></a>
            <div className="form-inline me-5">
                <Link to="/" className="d-inline-block lnk-fka-light">accueil</Link>
                <Link to="/a-propos" className="d-inline-block lnk-fka-light">&agrave; propos</Link>
                <Link to="/expertise" className="d-inline-block lnk-fka-light">expertise</Link>
                <Link to="/contact" className="d-inline-block lnk-fka-light">contact</Link>
                <Link to="/blog" className="d-inline-block lnk-fka-light">blog</Link>
                <a href="mailto:fkouassi.avocat@gmail.com" className="d-inline-block position-relative me-2">
                    <img src={email} width="20" height="15" className="d-inline-block align-baseline" alt="Envoyer email" title="Envoyer email" />
                </a>                
                <a href="https://www.linkedin.com/in/fran%C3%A7oise-kouassi-76a494170/" rel="noreferrer" target="_blank" className="d-inline-block position-relative ms-2">
                    <img src={linkedIn} width="20" height="20" className="d-inline-block align-baseline" alt="LinkedIn" title="LinkedIn" />
                </a>                
            </div>
        </nav>
    )
}

export default TopBar