import { useState } from "react"

const useContactScreen = () => {
    const [processing, setProcessing] = useState(false)
    const [success, setSuccess] = useState("")
    const [error, setError] = useState("")
    const sendMessage = evt => {
        const data = {name: evt.target["name"].value, email: evt.target["email"].value, message: evt.target["message"].value}
        setProcessing(true)
        setSuccess("")
        setError("")
        fetch("/api/send-message.php", {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then(r => {
            if (r.ok) {
                setSuccess("Votre message est envoyé avec succès.")
            } else {
                setError("Message non envoyé!")
            }
            return r
        })
        .catch(_ => {
            setError("Une erreur technique est survenue lors de l'envoi de votre message. Veuillez réessayer plus tard.")
        })
        .finally(() => {
            setProcessing(false)
        })
    }

    return [processing, success, error, sendMessage]
}

export default useContactScreen