import { Link } from 'react-router-dom'
import TopBar from 'components/topbar'
import './HomeScreen.css'

const HomeScreen = () => {

    return (
        <div>
            <div className="home-header">
                <TopBar fromHome="true" />
                <div className="home-h">
                    <h1>Fran&ccedil;oise</h1>
                    <h1>Kouassi</h1>
                    <h1>Avocat</h1>
                    <h2>Barreau de Saintes</h2>
                </div>
                <div className="home-b"><Link to="/a-propos" className="btn-fka-cons">CONSULTER</Link></div>
            </div>
            <div className="text-center pt-5 pb-5">
                <p><h1 className="text-uppercase">&agrave; propos</h1>
                    <div className="mb-3">
                        <span className="text-a-propos">ma&icirc;tre fran&ccedil;oise kouassi est une avocate exer&ccedil;ant<br/>dans les domaines du droit priv&eacute; et du droit public.</span>
                    </div>
                    <Link to="/a-propos" className="btn-fka-dark">Lire plus</Link>
                </p>
                <br/><br/>
                <p><h1>DOMAINES D'EXPERTISE</h1>
                    <div className="fond-expertise pt-5 pb-5">
                        <div className="block-expertise">
                            <div className="mb-4"><span className="text-expertise">Droit des personnes et de la famille : divorce, fixation/modification
                                de droits de visite et d'h&eacute;bergement, fixation / modification de pension
                                alimentaire, liquidation du r&eacute;gime matrimonial, succession,
                                assistance &eacute;ducative.<br/><br/>
                                Droits des obligations et de la responsabilit&eacute; : responsabilit&eacute;
                                contractuelle, responsabilit&eacute; d&eacute;lictuelle...</span>
                            </div>
                            <Link to="/expertise" className="btn-fka-light">Lire plus</Link>
                        </div>
                    </div>
                </p>
            </div>
        </div>
    )
}

export default HomeScreen