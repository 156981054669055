import logo from 'resources/logoLight.png'
import phone from 'resources/phoneIcon.png'
import email from 'resources/emailIcon.png'
import { Link } from 'react-router-dom'

const Footer = () => {

    return (
        <div className="footer"><div className="container">
            <div className="d-flex flex-row flex-wrap justify-content-center row align-items-end pt-5 pb-5">
                <div className="footer-col text-start"><div><img src={logo} height="75" alt="Logo FKA" /></div><div>COPYRIGHT@ 2022</div></div>
                <div className="footer-col">
                    Fran&ccedil;oise KOUASSI AVOCAT<br/>10 Place du Bastion, 17100 Saintes<br/>
                    <span><img className="d-inline-block align-middle" src={phone} height="15" alt="Icône téléphone" /> 06 66 78 40 28</span><br/>
                    <span><img className="d-inline-block align-middle" src={email} height="15" alt="Icône email" /> contact@francoise-kouassi-avocat.fr</span>
                </div>
                <div className="footer-col text-end pb-3"><div>&nbsp;</div><Link to="/contact" className="btn-fka-light">CONTACTER</Link></div>
            </div>
        </div></div>
    )
}

export default Footer