import Header from 'components/header'

const ExpertiseScreen = () => {

    return (
        <div>
            <Header title="expertise" />
            <div className="d-flex flex-row flex-wrap justify-content-center pt-5 pb-5 ps-2 pe-2">
                <div className="fka-body">
                    <div className="fka-title">Droit priv&eacute;</div>
                    <div className="page-content-text pt-4 pb-3">
                        <b>Droit des personnes et de la famille : </b>divorce, fixation / modification de droits de visite et d'h&eacute;bergement, 
                        fixation / modification de pension alimentaire, adoption, liquidation de r&eacute;gime matrimonial, succession, assistance &eacute;ducative.</div>
                    <div className="page-content-text pb-3"><b>Droit des obligations et de la responsabilit&eacute; : </b>responsabilit&eacute; contractuelle, responsabilit&eacute; d&eacute;lictuelle.</div>
                    <div className="page-content-text pb-3"><b>Droit du travail et de la s&eacute;curit&eacute; sociale : </b>actions pour licenciement, contentieux relatifs &agrave; la requalification du contrat
                        de travail et &agrave; la requalification des prestations de services en contrat de travail, reconnaissance d'un accident de travail ou d'une maladie professionnelle,
                        reconnaissance d'un &eacute;tat d'invalidit&eacute;, d'incapacit&eacute; permanente ou d'inaptitude au travail.</div>
                    <div className="page-content-text pb-3"><b>Droit immobilier : </b>gestion de contentieux locatifs, troubles de voisinage, contentieux de la construction.</div>
                    <div className="page-content-text pb-3"><b>Droit de l'ex&eacute;cution: </b>contestation saisie attribution, demande de mainlev&eacute;e saisie conservatoire.</div>
                </div>
                <div className="fka-body">
                    <div className="fka-title">Droit public</div>
                    <div className="page-content-text pt-4 pb-3">
                        <b>Droit administratif : </b>recours pour exc&egrave;s de pouvoir, recours de plein contentieux, responsabilit&eacute; administrative, recours administratif pr&eacute;alable obligatoire, 
                        contentieux relatifs &agrave; l'occupation du domaine public.</div>
                    <div className="page-content-text pb-3"><b>Droit de la fonction publique : </b>recours contre une sanction disciplinaire ou une sanction d&eacute;guis&eacute;e, contentieux relatifs au d&eacute;roulement de carri&egrave;re et 
                        aux conditions de travail.</div>
                    <div className="page-content-text pb-3"><b>Droit de l'urbanisme : </b>d&eacute;fense et contestation du permis de construire et de permis de d&eacute;molir, d&eacute;fense et contestation d'une opposition &agrave; une 
                        d&eacute;claration pr&eacute;alable de travaux.</div>
                    <div className="page-content-text pb-3"><b>Droit des &eacute;trangers et de la nationalit&eacute; : </b>demande de r&eacute;gularisation, demande de naturalisation, regroupement familial, reconduite &agrave; la fronti&eacute;re, 
                        droit d'asile.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExpertiseScreen